import { add } from "date-fns/esm"

const accountStore = {
  namespaced: true,
  state: {
    hairState: null,
    profile: null,
    marketingPrefs: {
      subscribedToMarketing: false,
      declinedMarketing: false,
      declinedMarketing_resetDate: null,
      isValidRoute: false
    }
  },
  mutations: {
    SET_HAIRSTATE: (state, { hairState }) => {
      state.hairState = hairState
    },
    SET_PROFILE: (state, payload) => {
      state.profile = payload
      // Explicity set the marketing preferences to allow it to be held in persistedState
      if (payload) {
        state.marketingPrefs["subscribedToMarketing"] =
          payload.subscribedToMarketing
      }
    },
    SET_MARKETING_DECLINED: (state, _val) => {
      state.marketingPrefs["declinedMarketing"] = _val
      state.marketingPrefs["declinedMarketing_resetDate"] = _val
        ? add(Date.now(), { days: 1 })
        : null
    },
    SET_VALID_NEWSLETTER_ROUTE: (state, _val) => {
      state.marketingPrefs["isValidRoute"] = _val
    }
  },
  actions: {
    SET_PROFILE({ commit }, payload) {
      commit("SET_PROFILE", payload)
    },
    RESET_PROFILE({ commit }) {
      commit("SET_PROFILE", null)
    }
  },

  getters: {
    GET_SHOW_NEWSLETTER: state => {
      return (
        state.marketingPrefs["isValidRoute"] &&
        !state.marketingPrefs["subscribedToMarketing"] &&
        !state.marketingPrefs["declinedMarketing"]
      )
    },

    GET_ORDERS: state => {
      return state.profile ? state.profile.orders : []
    },

    GET_SUBSCRIPTIONS: state => {
      return state.profile ? state.profile.subscriptions : []
    }
  }
}

export default accountStore
