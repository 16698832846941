function page(path) {
  return () =>
    import(
      /* webpackPrefetch: false */ /* webpackChunkName: "[request]" */ `@/views/${path}`
    ).then(m => m.default || m)
}

const routes = [
  {
    path: "/account",
    component: page("Account/Account.vue"),
    meta: {
      checkAuth: true
    },
    children: [
      {
        path: "/",
        name: "Account Dashboard",
        component: page("Account/AccountDashboard.vue")
      },
      {
        path: "orders",
        name: "Account Orders",
        component: page("Account/AccountOrders.vue")
      },
      {
        path: "profile",
        name: "Account Profile",
        component: page("Account/AccountProfile.vue")
      },
      {
        path: "subscriptions",
        name: "Account Subscriptions",
        component: page("Account/AccountSubscriptions.vue")
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: page("Login.vue"),
    /*     props: true,
     */ meta: {
      authRedirect: true
    }
  },

  {
    path: "/gift-card",
    name: "GiftCard Parent Page",
    component: page("GiftCardPage/GiftCardPage.vue"),
    children: [
      {
        path: "",
        name: "ProductGiftCard",
        component: page("GiftCardPage/GiftCardPagePurchase.vue")
      },
      {
        path: "balance",
        name: "GiftCard Balance",
        component: page("GiftCardPage/GiftCardPageBalance.vue")
      }
    ]
  },

  {
    path: "/products",
    name: "All Products",
    component: page("ListingPages/GenericProductListing.vue"),
    meta: {
      pageModel: "ProductListing",
      pseudoSlug: "products"
    },
    props: ({ meta }) => ({
      content: meta.pageData
    })
  },
  {
    path: "/products/category/:slug+",
    name: "Product Listing",
    component: page("ListingPages/GenericProductListing.vue"),
    meta: {
      rootPath: "/products/category",
      pageModel: "ProductListing"
    },
    props: ({ meta }) => ({
      content: meta.pageData
    })
  },
  {
    path: "/products/:slug",
    name: "Product",
    meta: {
      rootPath: "/products",
      pageModel: "Product"
    },
    component: page("ProductPage/ProductPage.vue"),
    props: ({ meta }) => ({
      content: meta.pageData
    })
  },

  {
    path: "/sets",
    name: "All Sets",
    component: page("ListingPages/GenericProductListing.vue"),
    meta: {
      pageModel: "ProductListing",
      pseudoSlug: "sets"
    },
    props: ({ meta }) => ({
      content: meta.pageData
    })
  },
  {
    path: "/sets/:slug",
    name: "ProductBundle",
    meta: {
      rootPath: "/sets",
      pageModel: "ProductBundle"
    },
    component: page("ProductPage/ProductPage.vue"),
    props: ({ meta }) => ({
      content: meta.pageData
    })
  },

  {
    path: "/bundles/:slug",
    redirect: "/sets/:slug"
  },
  {
    path: "/bundles",
    redirect: "/sets"
  },

  {
    path: "/collections/:slug",
    name: "CollectionPage",
    component: page("ListingPages/CollectionPage.vue"),
    meta: {
      rootPath: "/collections",
      pageModel: "CollectionPage"
    },
    props: ({ meta }) => ({
      content: meta.pageData
    })
  },
  {
    path: "/hairquiz",
    name: "Hair Quiz",
    meta: {},
    component: page("HairQuiz.vue")
  },
  {
    path: "/featured/bleach-kits",
    component: page("Campaign/BleachRange.vue"),
    meta: {
      pageModel: "Page"
    }
  },
  {
    path: "/reviews",
    component: page("Reviews/Reviews.vue"),
    name: "Product Reviews",
    meta: {
      pageModel: "Page",
      pseudoSlug: "reviews"
    },
    props: ({ meta }) => ({
      content: meta.pageData
    })
  },
  {
    path: "/:slug+",
    component: page("PageRenderer.vue"),
    meta: {
      pageModel: "Page"
    },
    props: ({ meta }) => ({
      content: meta.pageData
    })
  },
  {
    path: "/",
    name: "Home",
    meta: {
      rootPath: "/",
      pageModel: "Page"
    },
    component: page("PageRenderer.vue"),
    props: ({ meta }) => ({
      content: meta.pageData
    })
  },
  {
    path: "/page-not-found",
    name: "NOTFOUND",
    component: page("Page404.vue"),
    props: () => ({
      contentType: "MediaItem",
      elementId: "404_page"
    })
  },
  {
    path: "*",
    component: page("Page404.vue")
  }
]

export default routes
