import styles from "./../styles/settings.scss"

let resizeDebounce

const allBreakpoints = Object.keys(styles)
  .filter(key => key.split("_")[0].toUpperCase() === "BREAKPOINT")
  .map(key => key.split("_")[1])
  .map(bp => ({
    breakpoint: bp,
    size: Number(styles["BREAKPOINT_" + bp])
  }))
  .sort((a, b) => a.size - b.size)

const breakpointStore = {
  namespaced: true,

  state: {
    allBreakpoints,
    currentWidth: 0
  },

  mutations: {
    SET_CURRENT_WIDTH: (state, val) => {
      state.currentWidth = val
    }
  },

  actions: {
    UPDATE_CURRENT_WIDTH: ({ commit }, val) => {
      clearTimeout(resizeDebounce)
      resizeDebounce = setTimeout(() => {
        commit("SET_CURRENT_WIDTH", val)
      }, 250)
    }
  },

  getters: {
    GET_CURRENT_BREAKPOINT: state => {
      let _tempActiveBreakpoint = state.allBreakpoints[0]

      for (let i = 1; i < state.allBreakpoints.length; i++) {
        let _bpToCheck = state.allBreakpoints[i]
        // If the comparison BP is <= current width
        // AND
        // the comparison BP is larger than current temp BP
        if (
          _bpToCheck.size <= state.currentWidth &&
          _tempActiveBreakpoint.size < _bpToCheck.size
        ) {
          _tempActiveBreakpoint = _bpToCheck
        }
        // _tempActiveBreakpoint =
        //   _bpToCheck.size >= state.currentWidth &&
        //   (_tempActiveBreakpoint.size < state.currentWidth ||
        //     _bpToCheck.size < _tempActiveBreakpoint.size)
        //     ? _bpToCheck
        //     : _tempActiveBreakpoint
      }

      return _tempActiveBreakpoint
    },

    GET_IS_BELOW_BREAKPOINT: state => bp => {
      let _bp = validateBreakpoint(bp)
      return _bp !== false ? state.currentWidth < _bp : false
    },

    GET_IS_ABOVE_BREAKPOINT: state => bp => {
      let _bp = validateBreakpoint(bp)
      return _bp !== false ? state.currentWidth >= _bp : false
    },

    GET_IS_BETWEEN_BREAKPOINTS: state => (min, max) => {
      let _bpMin = validateBreakpoint(min)
      let _bpMax = validateBreakpoint(max)
      return state.currentWidth >= _bpMin && _bpMax > state.currentWidth
    }
  }
}

function validateBreakpoint(bp) {
  bp = bp.toLowerCase()
  if (Object.keys(styles).includes("BREAKPOINT_" + bp)) {
    return Number(styles["BREAKPOINT_" + bp])
  } else {
    return false
  }
}

export default breakpointStore
